import get from 'lodash/get';

/**
 * The normalized user maps 1-1 with the user state.
 * @param {*} cognitoUser The user from cognito pool
 * @param {*} mongoUser The user document from mongo DB
 */
const buildUserModel = (cognitoUser, mongoUser) => {
  const normalizedUser = {
    userId: get(cognitoUser, ['signInUserSession', 'accessToken', 'payload', 'sub']),
    email: get(cognitoUser, ['signInUserSession', 'idToken', 'payload', 'email']),
    roles: get(cognitoUser, ['signInUserSession', 'idToken', 'payload', 'cognito:groups']),
    jwtToken: get(cognitoUser, ['signInUserSession', 'idToken', 'jwtToken'], ''),
    firstName: get(mongoUser, 'firstName'),
    domain: get(mongoUser, 'domain'),
    lastName: get(mongoUser, 'lastName'),
    bookmarks: get(mongoUser, 'bookmarks'),
    subscriptions: get(mongoUser, 'subscriptions'),
    organisation: get(mongoUser, 'organisation'),
    allOrganisations: get(mongoUser, 'organisations'),
  };

  return normalizedUser;
};

export default buildUserModel;

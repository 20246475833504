import React, { useEffect, useState } from 'react';
import getRoutes from '../../routes/getRoutes';
import { filter, get, map } from 'lodash';
import { Tab, Tabs } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link, useLocation } from '@reach/router';

const Admin = ({ children, user }) => {
  const location = useLocation();

  const [value, setValue] = useState(() => {
    return location.pathname.split('/admin/')[1];
  });

  useEffect(() => {
    setValue(location.pathname.split('/admin/')[1]);
  }, [location]);

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Tabs variant="fullWidth" value={value} indicatorColor="primary" textColor="primary">
        {map(
          filter(getRoutes(user.roles), ({ menuItem }) => menuItem),
          ({ menuItem, url }) => (
            <Tab label={menuItem} key={url} component={Link} to={url} value={url} />
          ),
        )}
      </Tabs>

      <div
        style={{
          flexGrow: 1,
          position: 'relative',
        }}
      >
        {children}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: get(state, 'user'),
});

export default connect(mapStateToProps)(Admin);

import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import FooterLinks from './components/FooterLinks';
import { ReactComponent as CompanyIcon } from './assets/company.svg';
import cn from 'classnames';

const styles = () => ({
  footer: {
    position: 'absolute',
    bottom: '0.5rem',
    left: '50%',
    fontSize: '0.8rem',
    color: '#2b2a29',
    transform: 'translateX(-50%) scale(0.95)',
  },
  icon: {
    transform: 'translateY(18%) scale(0.95)',
  },
});

const Footer = ({ hide, classes, className, ...rest }) => {
  if (hide) return null;

  return (
    <footer className={cn(classes.footer, className)}>
      powered by <CompanyIcon className={classes.icon} /> {' | '}
      <FooterLinks {...rest} />
    </footer>
  );
};

export default withStyles(styles)(Footer);

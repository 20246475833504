import React, { useState, useCallback, useEffect } from 'react';

/**
 * 3rd party libraries
 */
import { connect } from 'react-redux';
import { Router, Redirect } from '@reach/router';

/**
 * Lodash
 */
import { isEmpty, get, includes, map } from 'lodash';

/**
 * Components
 */
import Layout from './components/Layout';
import AppLoader from './components/AppLoader';
import Authentication from './components/Authentication';
import SlackFeedback from './components/SlackFeedback';

/**
 * Screens
 */
import Home from './screens/Home';
import Databoard from './screens/Databoard';
import NotificationCenter from './screens/Notification';
import CookiePolicy from './screens/CookiePolicy';
import PrivacyPolicy from './screens/PrivacyPolicy';
import Terms from './screens/Terms';

import getRoutes from './screens/routes/getRoutes';

import { setCurrentUser, setUserNotifications } from './state/user/actions';
import { trackRefreshToken, trackLandingPage } from './state/analytics/actions';
import currentAuthenticatedUser from './components/Authentication/actions/currentAuthenticatedUser';
import CookieBot from './components/CookieBot';

/**
 * Global Styles
 */
import './app.css';
// import Landing from './screens/Landing';
import HomePage from './screens/HomePage';
import {
  NEW_ACCOUNT_SCREEN,
  SIGN_IN_SCREEN,
  WELCOME_SCREEN,
} from './components/Authentication/types';
import { API, Hub } from 'aws-amplify';
import Report from './screens/Report';
import Briefing from './screens/Briefing';
import { navigate } from '@reach/router';
import Admin from './screens/Admin/Admin/Admin';
import Welcome from './components/Authentication/screens/Welcome';

const PUBLIC_PAGE_ROUTES = ['/cookie-policy', '/privacy-policy', '/terms', '/'];

const App = ({
  userState,
  doSetUser,
  doTrackRefreshToken,
  doTrackLandingPage,
  onSetUserNotifications,
}) => {
  const [loader, setLoader] = useState(false);

  const fetchUser = useCallback(async () => {
    setLoader(true);
    try {
      const model = await currentAuthenticatedUser();

      // This function execs in sync in order to avoid race conditions
      // It writes the sessionId, which it is read from all the rest analytic actions
      await doTrackRefreshToken(model.userId, model.email);

      // Getting the notifications
      const params = {
        headers: {
          Authorization: `Bearer ${model.jwtToken}`,
        },
      };
      const notifications = await API.get('users', `/users/${model.userId}/notifications`, params);
      onSetUserNotifications(notifications);
      doSetUser(model);
    } catch (error) {
      doTrackLandingPage();
    } finally {
      setLoader(false);
    }
  }, [doSetUser]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      if (event === 'customOAuthState') {
        const { redirect } = JSON.parse(data || {});

        if (redirect) {
          navigate(redirect);
        }
      }
    });

    return unsubscribe;
  }, []);

  const isPrivateRoute = !includes(PUBLIC_PAGE_ROUTES, get(window, ['location', 'pathname'], ''));
  const isAnonymousVisit = isEmpty(userState.userId) && !loader;
  const showAuthAndKeepRoute = isAnonymousVisit && isPrivateRoute;

  return (
    <Layout>
      <AppLoader show={loader} />
      <SlackFeedback />
      <CookieBot />
      {showAuthAndKeepRoute && (
        <Authentication initialScreen={SIGN_IN_SCREEN} onMount={fetchUser} />
      )}
      {isAnonymousVisit && (
        <Router style={{ height: '100%' }}>
          {!showAuthAndKeepRoute && <Welcome path="/" />}
          {!showAuthAndKeepRoute && (
            <Authentication initialScreen={SIGN_IN_SCREEN} onMount={fetchUser} path="/login" />
          )}
          {!showAuthAndKeepRoute && (
            <Authentication initialScreen={NEW_ACCOUNT_SCREEN} onMount={fetchUser} path="/signup" />
          )}
          <CookiePolicy path="/cookie-policy" />
          <PrivacyPolicy path="/privacy-policy" />
          <Terms path="/terms" />
          {/* <Landing path="/landing" /> */}
        </Router>
      )}
      {!isEmpty(userState.userId) && (
        <Router style={{ height: '100%' }} primary={false}>
          <HomePage path="/" />
          <Home path="/dashboards" />
          <Home path="/daa" />
          <Home path="/reports" />
          <Report path="/reports/:reportId" />
          <Report path="/reports/:reportId/pages/:pageNumber" />
          <Briefing path="/briefings/:briefingId" />
          <Home path="/briefings" />
          <CookiePolicy path="/cookie-policy" />
          <PrivacyPolicy path="/privacy-policy" />
          <Terms path="/terms" />
          <Databoard path="/dashboards/:databoardId" />
          <Databoard path="/dashboards/:databoardId/charts/:chartName" />
          <Databoard path="/daa/:databoardId" />
          <Databoard path="/daa/:databoardId/charts/:chartName" />
          <NotificationCenter path="/notifications" />

          {!isEmpty(userState.roles) && (
            <Admin path="/admin">
              {map(getRoutes(userState.roles), ({ Component, url }) => (
                <Component key={url} path={url} />
              ))}
              <Redirect from="/" to="/admin/analytics" noThrow />
              <Redirect from="/*" to="/admin/analytics" noThrow />
            </Admin>
          )}

          <Redirect from="/databoards/:databoardId" to="/dashboards/:databoardId" noThrow />
          <Redirect
            from="/databoards/:databoardId/charts/:chartName"
            to="/dashboards/:databoardId/charts/:chartName"
            noThrow
          />
          <Redirect from="/" to="/" default noThrow />
        </Router>
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  userState: get(state, 'user'),
});

const mapDispatchToProps = (dispatch) => ({
  doSetUser: (user) => dispatch(setCurrentUser(user)),
  doTrackRefreshToken: (userId, email) => dispatch(trackRefreshToken(userId, email)),
  doTrackLandingPage: () => dispatch(trackLandingPage()),
  onSetUserNotifications: (notifications) => dispatch(setUserNotifications(notifications)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

import { API } from 'aws-amplify';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { SnackBar } from '../../../components/UI';
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  withStyles,
} from '@material-ui/core';
import currentAuthenticatedUser from '../../../components/Authentication/actions/currentAuthenticatedUser';
import { setCurrentUser } from '../../../state/user/actions';

const styles = (theme) => ({
  table: {
    '& .MuiTableCell-head': {
      fontWeight: 'bold',
      fontSize: '1.1rem',
      color: theme.palette.custom.gray5,
    },
    '& .MuiTableRow-root': {
      height: '4.9rem',
    },
    '& .MuiTableCell-root.MuiTableCell-body': {
      cursor: 'pointer',
      fontSize: '1.1rem',
      color: theme.palette.custom.black,
    },
  },
});

const General = ({ jwtToken, userId, userDomain, doSetUser }) => {
  const [organisations, setOrganisations] = useState([]);
  const [domain, setDomain] = useState(userDomain || '');
  const [loader, setLoader] = useState(true);
  const [orgLoader, setOrgLoader] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const results = await API.get('users', `/organisations`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });

        setOrganisations(results);
      } catch (e) {
        console.error(e);

        const message = get(
          e,
          ['response', 'data', 'message'],
          'You do not have permission to access',
        );

        setError(message);
      } finally {
        setLoader(false);
      }
    })();
  }, [jwtToken]);

  const onViewAs = async (domain) => {
    setOrgLoader(true);

    try {
      const result = await API.patch('users', `/users/${userId}/domain`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: {
          domain,
        },
      });

      const model = await currentAuthenticatedUser();

      doSetUser(model);

      setDomain(result.domain);
    } catch (e) {
      console.error(e);

      const message = get(
        e,
        ['response', 'data', 'message'],
        'You do not have permission to access',
      );

      setError(message);
    } finally {
      setOrgLoader(false);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
        padding: '40px',
        paddingTop: '60px',
      }}
    >
      {loader || orgLoader ? (
        <CircularProgress style={{ margin: 'auto' }} />
      ) : (
        <>
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px', alignSelf: 'center' }}>
            <FormControl
              style={{
                width: '400px',
              }}
            >
              <InputLabel>View As</InputLabel>

              <Select value={domain} onChange={(e) => onViewAs(e.target.value)}>
                {organisations
                  .filter((org) => !org.domains.includes('*'))
                  .map((org) => (
                    <MenuItem key={org._id} value={org.domains[0]}>
                      {org.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            {domain && (
              <Button variant="contained" color="primary" size="small" onClick={() => onViewAs('')}>
                Reset
              </Button>
            )}
          </div>
        </>
      )}

      <SnackBar open={Boolean(error)} message={error} severity="warning" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  jwtToken: get(state, ['user', 'jwtToken'], ''),
  userId: get(state, ['user', 'userId'], []),
  userDomain: get(state, ['user', 'domain'], ''),
});

const mapDispatchToProps = (dispatch) => ({
  doSetUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(General));

import React, { useState } from 'react';

import { withStyles } from '@material-ui/core/styles';

import Welcome from './screens/Welcome';
import SignIn from './screens/SignIn';
import NewAccount from './screens/NewAccount';
import VerifyEmail from './screens/VerifyEmail';
import SuccessScreen from './screens/SuccessScreen';
import ExistingMember from './screens/ExistingMember';
import ForgotPassword from './screens/ForgotPassword';
import ResetPassword from './screens/ResetPassword';
import NewPassword from './screens/NewPassword';
import Footer from '../Footer';

import AuthBackground from '../../assets/auth-background.png';
import CdFoundation from '../../assets/cd-foundation.png';
import Dell from '../../assets/dell.png';
import DigitalOcean from '../../assets/digitalocean.png';
import Google from '../../assets/google.png';
import Microsoft from '../../assets/microsoft.png';
import Cisco from '../../assets/cisco.png';
import Intel from '../../assets/intel.png';
import Linux from '../../assets/linux.png';
import Meta from '../../assets/meta.png';
import Okta from '../../assets/okta.png';
import Qualcomm from '../../assets/qualcomm.png';
import Sap from '../../assets/sap.png';
import Sony from '../../assets/sony.png';
import Square from '../../assets/square.png';
import Stripe from '../../assets/stripe.png';

import {
  WELCOME_SCREEN,
  SIGN_IN_SCREEN,
  NEW_ACCOUNT_SCREEN,
  VERIFY_EMAIL_SCREEN,
  SUCCESS_SCREEN,
  EXISTING_MEMBER_SCREEN,
  FORGOT_PASSWORD_SCREEN,
  RESET_PASSWORD_SCREEN,
  NEW_PASSWORD_SCREEN,
} from './types';
import { Typography } from '@material-ui/core';

const logos = [
  CdFoundation,
  Dell,
  DigitalOcean,
  Google,
  Microsoft,
  Cisco,
  Intel,
  Linux,
  Meta,
  Okta,
  Qualcomm,
  Sap,
  Sony,
  Square,
  Stripe,
];

const screenManager = (screen) => {
  switch (screen) {
    case WELCOME_SCREEN:
      return Welcome;
    case SIGN_IN_SCREEN:
      return SignIn;
    case NEW_ACCOUNT_SCREEN:
      return NewAccount;
    case VERIFY_EMAIL_SCREEN:
      return VerifyEmail;
    case SUCCESS_SCREEN:
      return SuccessScreen;
    case EXISTING_MEMBER_SCREEN:
      return ExistingMember;
    case FORGOT_PASSWORD_SCREEN:
      return ForgotPassword;
    case RESET_PASSWORD_SCREEN:
      return ResetPassword;
    case NEW_PASSWORD_SCREEN:
      return NewPassword;
    default:
      return React.Fragment;
  }
};

const styles = (theme) => ({
  root: {
    minHeight: '100%',
    display: 'flex',
    position: 'relative',
  },
  pageWrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${AuthBackground})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    padding: '100px 20px',
    '& .content-wrapper': {
      '& .content-wrapper-inner': {
        maxWidth: '550px',
      },
    },
    '& .MuiTypography-h1': {
      color: '#fecc00',
      fontSize: '42px',
      fontWeight: 900,
      maxWidth: '520px',
    },
    '& .MuiTypography-h5': {
      color: 'white',
    },
    '& .MuiTypography-body1': {
      fontSize: '21px',
      color: 'white',
      '&.italic': {
        fontStyle: 'italic',
      },
      '&.title': {
        fontWeight: 'bold',
        color: '#fecc00',
      },
    },
    '& .MuiTypography-body2': {
      fontSize: '18px',
      color: 'white',
      '&.italic': {
        fontStyle: 'italic',
      },
      '& .link': {
        fontStyle: 'normal',
        color: '#fecc00',
      },
    },
    '& ul': {
      listStyle: 'none',
      padding: '0',

      '& li': {
        position: 'relative',
        paddingLeft: '20px',

        '&::before': {
          content: '""',
          backgroundColor: '#fecc00',
          height: '6px',
          width: '6px',
          borderRadius: '50%',
          position: 'absolute',
          left: '0',
          top: '50%',
          transform: 'translateY(-50%)',
        },
      },
    },
    '& .logo-wrapper': {
      maxWidth: '750px',
      display: 'flex',
      flexWrap: 'wrap',
      '& > img': {
        width: '93px',
        height: '53px',
      },
    },
  },
  footerWhite: {
    color: 'white',
    '& a': {
      color: 'white',
    },
    '& .company-logo-1': {
      fill: 'white!important',
    },
  },
  authWrapper: {
    width: '30%',
    minWidth: '520px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  authFormWrapper: {
    width: '520px',
    padding: '80px',
  },
});

const Authentication = ({ classes, initialScreen }) => {
  const [screen, setScreen] = useState(initialScreen);
  const [codeDeliveryDetails, setCodeDeliveryDetails] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState({});
  const [previousError, setPreviousError] = useState({});

  const ScreenComponent = screenManager(screen);
  const signInProps = screen === SIGN_IN_SCREEN ? { setUser, setEmail, setPassword } : {};
  const existingMemberProps =
    screen === EXISTING_MEMBER_SCREEN ? { setUser, setEmail, setPassword } : {};
  const newPasswordProps = screen === NEW_PASSWORD_SCREEN ? { user } : {};
  const forgotProps =
    screen === FORGOT_PASSWORD_SCREEN ? { setCodeDeliveryDetails, setEmail, setPreviousError } : {};
  const resetProps =
    screen === RESET_PASSWORD_SCREEN ? { codeDeliveryDetails, email, setPassword } : {};
  const newAccountProps = screen === NEW_ACCOUNT_SCREEN ? { setEmail, setPassword } : {};
  const verifyEmailProps = screen === VERIFY_EMAIL_SCREEN ? { email, password, previousError } : {};
  const successScreenProps = screen === SUCCESS_SCREEN ? { email, password } : {};
  const screenProps = {
    setScreen,
    ...forgotProps,
    ...resetProps,
    ...newAccountProps,
    ...verifyEmailProps,
    ...successScreenProps,
    ...signInProps,
    ...newPasswordProps,
    ...existingMemberProps,
  };

  return (
    <div className={classes.root}>
      <div className={classes.pageWrapper}>
        <div className="content-wrapper">
          <div className="content-wrapper-inner">
            <Typography variant="h1" style={{ marginBottom: '15px' }}>
              Unlock Exclusive Tech Insights with One Sign-in
            </Typography>

            <Typography variant="body2">
              Gain instant access to cutting-edge research, trusted by professionals worldwide:
            </Typography>

            <ul style={{ margin: '25px 0' }}>
              <li>
                <Typography variant="body2">
                  47+ full access, in-depth Tech market reports
                </Typography>
              </li>

              <li>
                <Typography variant="body2">Competitive and benchmarking studies</Typography>
              </li>

              <li>
                <Typography variant="body2">Expert analyses and trends</Typography>
              </li>

              <li>
                <Typography variant="body2">Product, brand awareness and pricing data</Typography>
              </li>

              <li>
                <Typography variant="body2">Interactive dashboards</Typography>
              </li>

              <li>
                <Typography variant="body2">
                  Thought-leadership reports in partnership with industry leaders
                </Typography>
              </li>

              <li>
                <Typography variant="body2">
                  High-quality data, powered by an industry-leading methodology
                </Typography>
              </li>
            </ul>

            <Typography variant="body1" className="italic title" style={{ marginBottom: '5px' }}>
              Sign up once. Open up a world of insights.
            </Typography>

            <Typography variant="body1" className="italic">
              No paywall. Free, public reports are immediately available.
            </Typography>

            <Typography variant="body2" className="italic" style={{ margin: '25px 0' }}>
              For premium research, connect with our team to tailor solutions to your needs.{' '}
              <a className="link" href="https://slashdata.co">
                Get in touch.
              </a>
            </Typography>
          </div>

          <Typography variant="h5" style={{ margin: '15px 0' }}>
            They trust us
          </Typography>

          <div className="logo-wrapper">
            {logos.map((logo, index) => (
              <img key={index} src={logo} />
            ))}
          </div>
        </div>
      </div>

      <div className={classes.authWrapper}>
        <div className={classes.authFormWrapper}>
          <ScreenComponent {...screenProps} />
        </div>
      </div>

      <Footer className={classes.footerWhite} />
    </div>
  );
};

export default withStyles(styles)(Authentication);
